<template>
  <div class="thankyou">
    <main>
      <router-link to="/">
        <img class="logo__large" src="@/assets/icons/logo_white.svg" alt="teamee Logo" title="teamee - Home" />
      </router-link>
      <div class="txt">
        <h1>Thank You!</h1>
        <p class="h6">Danke, dass du ein Teil von teamee warst!</p>
        <p class="h6">Wir würden uns sehr freuen, dich irgendwann wieder sehen zu können.</p>
      </div>
    </main>
    <AppFooterRegistration />
  </div>
</template>

<script>
import AppFooterRegistration from "@/components/AppFooterRegistration.vue";

export default {
  name: "ThankYou",
  components: {
    AppFooterRegistration,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

main {
  height: calc(100vh - 83px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 24px;
  .logo__large {
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    margin: auto;
    @media screen and (max-width: 960px) {
      top: 20px;
    }
  }
}
</style>
